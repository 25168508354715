




















































































	import {Component, Vue} from 'vue-property-decorator';
	import CusTable from "@/components/table";
	import MixinEdu from "@/views/education/Mixins/mixin-edu";
	@Component({
		name: 'Subject',
		components:{CusTable},
		mixins: [ MixinEdu ]
	})
	export default class Subject extends Vue{
		constructor(prop) {
			super(prop);
		};
    private tableHead:Array<{[key:string]: any}> = [
      {
        label: '序号',
        width: '50',
        align: 'left',
        render: (row, column, cellValue, index) => {
          return index + 1
        }
      },
      {
        property: 'name',
        label: '学科名称',
        width: 'auto',
        align: 'left'
      },
      {
        property: 'gradeRangeName',
        label: '学段名称',
        width: 'auto',
        align: 'left'
      },
      {
        property: 'modifierName',
        label: '修改人',
        width: 'auto',
        align: 'left'
      },
      {
        property: 'modifyTime',
        label: '修改时间',
        width: 'auto',
        align: 'left',
	      render: (row, clumn, cellValue, index) => {
          return new Date(row.modifyTime).toLocaleString();
	      }
      }
    ];
		private AddDialogVisible = false;
		private rowId = '';
		private inputVal = '';
		private gradeVal = '';
		private gradeOptions = [];
		$refs!: {table: CusTable}
		/**
		 * 批量停用/启用
		 */
		private batchChangeStatusHandle(status) {
		  this.batchChangeStatus(status, this.$refs.table);
		};

    /**
     * 停用 && 启用
     */
    private handleChangeStatus(idx, row: never | any) {
      this.selectedArr = [row];
      this.batchChangeStatus((row.status == 1 ? 0 : 1), this.$refs.table)
    }

    private handleDelete(idx, row: never | any) {
      this.selectedArr = [row];
      this.batchDeleteHandle(this.$refs.table);
    }

    private selectionChange(ev) {
      this.selectedArr = ev;
    };

    private handleEdit(idx, row:any) {
			this.rowId = row.id;
			this.inputVal = row.name;
			this.gradeVal = row.gradeRangeCode;
			this.AddDialogVisible = true;
    }

    private async getGradeOptions() {
      const res:any = await this.axios.get(`/system/dictionary/queryPage?typeCode=GRADE_RANGE`);
      this.gradeOptions = res.records;
    }

    private async addSubject() {
      if(/^[\u4e00-\u9fa5]+$/i.test(this.inputVal)){
        const res: any = await this.axios.post('/system/dictionary/saveOrUpdate', {typeCode: (this.$route as any).meta.type, name: this.inputVal, parentCode: this.gradeVal, id: this.rowId});
        if (res.result) {
          this.$message.success('新增成功');
          this.AddDialogVisible = false;
        } else{
          this.$message.warning(res.msg)
        };
        this.$refs.table.request();
      } else {
        this.$message.warning('学科名称必须是中文')
      }
    };

    public created() {
			this.getGradeOptions();
    }
	}
